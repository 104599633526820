<template>
  <div class="invoice">
    <invoice-header text="开具发票"></invoice-header>
    <router-link class="invoice__item a-label-reset" to="/invoice/order">订单</router-link>
    <router-link class="invoice__item a-label-reset" to="/invoice/history">开票历史</router-link>
    <router-link class="invoice__item a-label-reset" to="/invoice/help">开票帮助</router-link>
  </div>
</template>

<script>
export default {
  name: "invoice",
  components: {
    "invoice-header": require("./header").default
  },
  created() {

  },
  data() {
    return {};
  }
};
</script>

<style lang="scss">
.invoice {
  &__item {
    display: block;
    font-size: 0.229rem;
    line-height: 0.5rem;
    padding: 0.3rem;
    color: #333333;
    position: relative;
    box-sizing: border-box;
    border-bottom: 0.01rem solid #e6e6e6;

    &:nth-child(2) {
      border: 0.2rem solid #e6e6e6;
      border-left: none;
      border-right: none;
    }

    &:after {
      content: "";
      position: absolute;
      right: 0.15rem;
      top: 50%;
      margin-top: -0.25rem;
      width: 0.5rem;
      height: 0.5rem;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url(~@/assets/icon_right_arrow.png);
    }
  }
}
</style>
